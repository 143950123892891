import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to properly tow a corvette c4`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b8060c1f56692b2455cc8919cf71c560/f74b2/c4_corvette_tow.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAABTDqeJJXI/8QAGhABAAMAAwAAAAAAAAAAAAAAAQADEgIQEf/aAAgBAQABBQIsrV51x17kmTr/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAbEAABBAMAAAAAAAAAAAAAAAARAAEQMSFRof/aAAgBAQAGPwIDOgr5FMqaP//EABwQAAICAgMAAAAAAAAAAAAAAAABESExcVFh4f/aAAgBAQABPyG5F6TIbmUT6Co+Xocs3aF4xC4R/9oADAMBAAIAAwAAABAgz//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/EKj/xAAWEQEBAQAAAAAAAAAAAAAAAAAAIRH/2gAIAQIBAT8QmI//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhQTGBUWFx/9oACAEBAAE/EA9JUMXugTxxWcT6VDvdh4JriNtobpcAYDpA8AHon//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "corvette c4 towing",
            "title": "corvette c4 towing",
            "src": "/static/b8060c1f56692b2455cc8919cf71c560/e5166/c4_corvette_tow.jpg",
            "srcSet": ["/static/b8060c1f56692b2455cc8919cf71c560/f93b5/c4_corvette_tow.jpg 300w", "/static/b8060c1f56692b2455cc8919cf71c560/b4294/c4_corvette_tow.jpg 600w", "/static/b8060c1f56692b2455cc8919cf71c560/e5166/c4_corvette_tow.jpg 1200w", "/static/b8060c1f56692b2455cc8919cf71c560/f74b2/c4_corvette_tow.jpg 1247w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Jonathan Einfeld built a trailer for his Corvette C4 drift car, and he needed to tow it in order to take it to events. He used his 1999 Chevrolet Suburban but was unhappy with the fuel economy. It didn't help that the trailer was a little bulky. So, he built a gooseneck hitch and towed it with it.`}</p>
    <p>{`When towing a C4 Corvette, it's important to choose a vehicle with a flat bed. This will keep the vehicle safe and secure in the back. The tow truck's wheels must be level to avoid damaging the car's suspension. It's also necessary to turn the emergency brake off in the car. This will prevent the vehicle from turning over on the trailer hitch.`}</p>
    <p>{`A Corvette C4 can also be towed with a small boat. If it is under one ton, the tow vehicle can tow it. The tow truck must have a flat bed. Don't put the winch or tow hook on the suspension. Turn the ignition to neutral and turn off the emergency brake. Then, you can hook the vehicle up to the tow truck. When the tow truck has successfully hooked your car, it is time to let the tow truck leave. One more thing. Before you hire a tow truck, make sure you know the vehicle's weight capacity. The tow truck should be able to tow the car safely.`}</p>
    <p>{`When towing a corvette c4, remember that the vehicle must be in the proper towing brackets and trailering packages. Ensure the towing company knows how to get the car out of the garage and onto the trailer. Ask the tow truck company what features they recommend for your car. Then, let them know about the weight of your cargo and the weight of your passengers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      